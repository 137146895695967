import getStateValue from '../../../../../../helpers/getStateValue';

import { MAP_DASH_SORT } from './index';

const paramsToQueryString = (params) => Object
  .keys(params)
  .map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

const getEcomDashApiUrl = () => {
  const result = {
    ecommerceDashApiService: getStateValue('apiUrls.ecomDashApiUrl'),
  };

  try {
    const { origin } = new URL(result.ecommerceDashApiService);

    result.ecomDashStoreApiUrl = `${origin}/v3.0/`;
  } catch (error) {
    console.error(error);
  }

  return result;
};

const {
  ecommerceDashApiService,
  ecomDashStoreApiUrl,
} = getEcomDashApiUrl();

export default {
  totalProductCount: (instanceId) => `${ecommerceDashApiService}product/all?instanceId=${instanceId}&${paramsToQueryString({
    offset: 1,
    limit: 1,
    ExcludeVariantDetails: true,
  })}`,
  categories: (instanceId, params) => `${ecommerceDashApiService}product/all?instanceId=${instanceId}&${paramsToQueryString({
    ...params,
    ExcludeVariantDetails: true,
  })}`,
  allProducts: (instanceId, params) => {
    const {
      offset,
      limit,
      sortBy,
      category: categoryId,
    } = params || {};
    const { key: sortKey, value: sortValue } = MAP_DASH_SORT[sortBy] || {};
    // TODO: remove mock

    // offset it's a page number
    // limit it's a page size

    return `${ecomDashStoreApiUrl}product/all?instanceId=${instanceId}&${paramsToQueryString({
      offset: 1,
      ExcludeVariantDetails: true,
      ...offset && limit && { offset: Math.ceil(offset / limit) + 1 || 1 },
      ...limit && { limit },
      ...(categoryId && { categoryId }),
      ...(sortKey && { [sortKey]: sortValue }),
    })}`;
  },
  allProductsForSingle: (instanceId, params) => {
    const {
      sortBy,
      category: categoryId,
    } = params || {};
    const { key: sortKey, value: sortValue } = MAP_DASH_SORT[sortBy] || {};

    return `${ecommerceDashApiService}product/all?instanceId=${instanceId}&${paramsToQueryString({
      ...(categoryId && { categoryId }),
      ...(sortKey && { [sortKey]: sortValue }),
      limit: 1000,
    })}`;
  },
  product: (instanceId, productId) => `${ecommerceDashApiService}product/details/?instanceId=${instanceId}&productId=${productId}`,
  currency: (instanceId) => `${ecommerceDashApiService}settings/currency?instanceId=${instanceId}`,
};
